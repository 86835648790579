<template>
  <div class="menu">
    <p class="menu__plan">Thời gian sử dụng còn lại : {{ getRest()() }}Ngày</p>
    <!-- お知らせ -->
    <div class="menu__item" @click="$router.push({ name: 'MenuNotification' })">
      <div class="menu__item__icon"><img src="@/assets/img/notification-gray.svg"></div>
      <p class="menu__item__text">Thông báo<span v-if=hasUnreadNotices class="menu__item__text__unread"></span></p>
      <img class="menu__item__arrow" src="@/assets/img/arrow-gray.svg">
    </div>
    <!-- プロフィール編集 -->
    <div class="menu__item" @click="$router.push({ name: 'ProfileEdit' })">
      <div class="menu__item__icon"><img src="@/assets/img/user.svg"></div>
      <p class="menu__item__text">Thay đổi thông tin cá nhân</p>
      <img class="menu__item__arrow" src="@/assets/img/arrow-gray.svg">
    </div>
    <!-- Terms and policy -->
    <a class="menu__item" href="https://docs.google.com/document/d/1dRH6nhXH23toZ-izrE2v_d7IefMY8KPepMxFYgiUf-g/edit?usp=sharing" target="_blank">
      <div class="menu__item__icon"><img src="@/assets/img/terms-gray.svg"></div>
      <p class="menu__item__text">Điều khoản và quy định</p>
      <img class="menu__item__arrow" src="@/assets/img/arrow-gray.svg">
    </a>
    <!-- ログアウト -->
    <div class="menu__item" @click="doLogout()">
      <div class="menu__item__icon"><img src="@/assets/img/logout-gray.svg"></div>
      <p class="menu__item__text">Đăng xuất</p>
      <img class="menu__item__arrow" src="@/assets/img/arrow-gray.svg">
    </div>
    <!-- 退会 -->
    <div class="menu__item" @click="$router.push({ name: 'MenuCancel' })">
      <div class="menu__item__icon"><img src="@/assets/img/unsubscribe-gray.svg"></div>
      <p class="menu__item__text">Thoát khỏi ứng dụng</p>
      <img class="menu__item__arrow" src="@/assets/img/arrow-gray.svg">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      // 残りプラン数
      plan: ''
    }
  },
  computed: {
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['user/user']
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Boolean} 未読のお知らせがあるか
     */
    hasUnreadNotices () {
      return this.getUnreadNoticeIDs()().length > 0
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('user', ['updateUser']),
    ...mapGetters('pin', ['getRest']),
    ...mapGetters('notice', ['getUnreadNoticeIDs']),
    /**
     * ログアウトして、ログインに飛ばす
     */
    doLogout () {
      // ログイン端末IDを削除する
      this.updateUser({ uid: this.uid, param: { deviceID: null } }).then(() => {
        this.logout().then(() => {
          this.$router.push({ name: 'Login' })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.menu {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  &__plan {
    margin: 0;
    font-size: 17px;
    font-weight: bold;
    color: #393939;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  &__item {
    display: block;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #d2d2d2;
    &__icon {
      display: inline-block;
      vertical-align: middle;
      height: 18px;
      width: 18px;
      margin-right: 8px;
      text-align: center;
      img {
        height: 100%;
      }
    }
    &__text {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 60px);
      margin: 0;
      font-size: 17px;
      color: #393939;
      &__unread {
        display: inline-block;
        vertical-align: top;
        height: 8px;
        width: 8px;
        margin-left: 7px;
        border-radius: 50%;
        background-color: #ff9534;
      }
    }
    &__arrow {
      display: inline-block;
      vertical-align: middle;
      height: 16px;
    }
  }
}
</style>
